import { IconButton, useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import Typography from "../../components/common/Typography";
import { SyntheticEvent, useEffect, useState } from "react";
import { Tab, TabPanel, Tabs } from "../../components/common/Tabs";
import Divider from "../../components/common/Divider";
import TextButton from "../../components/common/TextButton";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../components/common/Table";
import Button from "../../components/common/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import ConstructionIcon from "@mui/icons-material/Construction";
import { AddTopic } from "./AddTopic";
import { AddEntity } from "./AddEntity";
import { AddPolicy } from "./AddPolicy";
import NoSearchIcon from "../../assets/img/No_Search_illustration.svg";
import {
  listPoliciesAsync,
  deletePolicyAsync,
  fetchEntitiesSlice,
  fetchTopicsSlice as fetchDefinedTopicsSlice,
} from "../../redux/slices/policySlice";
import { useDispatch } from "react-redux";
import DeletePolicy from "./DeletePolicy";
import Loader from "../common/Loader";
import moment from "moment";
import {
  deleteTopicSlice,
  fetchTopicSlice,
} from "../../redux/slices/topicSlice";
import { getCustomerId } from "../../utils/SessionHelper";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Tooltip from "../common/Tooltip";
import { Menu, MenuItem } from "../common/Menu";
import ApplyTopicToAppsDialog from "./ApplyTopicToAppsDialog";
import { fetchFrameworksSlice } from "../../redux/slices/dashboardSlice";
import { TOASTER_TYPES, Toaster } from "../../utils/toaster";
import DeleteTopic from "./DeleteTopic";
import { capitalizeFirstLetter } from "../../utils/utils.js";
import { AppDispatch } from "../../redux/store";
import MoreVert from "@mui/icons-material/MoreVert";
import ViewPolicy from "./ViewPolicy";

const TOPIC_STATUS = {
  TRAINING: "training",
  PENDING: "pending",
  EVALUATING: "evaluating",
  SUCCESS: "success",
  FAIL: "failed",
  ABORTED: "aborted",
  CANCELLED: "cancelled",
  MIN_SAMPLE_CHECK: "sample_count_check",
  VARIANCE_CHECK: "variance_check",
  CENTROID_CHECK: "centroid_check",
  EMBEDDING_GENERATION: "embedding_generation",
  LOAD_DATA: "load_data",
  QUEUED: "queued",
};

const TOPIC_STATUS_VALUES = {
  training: "training",
  pending: "pending",
  evaluating: "evaluating",
  success: "success",
  failed: "failed",
  aborted: "aborted",
  cancelled: "cancelled",
  sample_count_check: "sample count check",
  variance_check: "variance check",
  centroid_check: "centroid check",
  embedding_generation: "embedding generation",
  load_data: "load data",
  queued: "queued",
};

const TOPIC_STATUS_CODES = {
  success: 200,
  error: 500,
};

type TableAlign =
  | "left"
  | "center"
  | "right"
  | "justify"
  | "inherit"
  | undefined;

interface TableCol {
  title: string;
  render: (any) => JSX.Element;
  align?: TableAlign;
  field?: string;
}

interface DataTableProps {
  columns: Array<{
    title: string;
    render: (any) => JSX.Element;
    align?: TableAlign;
    field?: string;
  }>;
  dataList;
  isFetchingData?: boolean;
}

const DataTable = ({ columns, dataList, isFetchingData }: DataTableProps) => {
  const theme = useTheme();
  const styles = {
    tableContainer: {
      maxHeight: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      backgroundColor: theme.palette.surface10.main,
      paddingY: theme.spacing(1.5),
    },
    tableHead: {
      "&.MuiTableCell-head": {
        paddingY: theme.spacing(1.25),
        zIndex: 0,
      },
    },
    tableCell: {
      fontSize: "13px",
      padding: theme.spacing(2),
      "&.MuiTableCell-root ": {
        borderBottom: `1px solid ${theme.palette.surface20.main}`,
      },
      verticalAlign: "middle",
    },
    noDataContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    noDataMsg: {
      fontSize: "13px",
      color: theme.palette.surface60.main,
    },
  };

  return (
    <TableContainer sx={styles.tableContainer}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns?.map((col, index) => (
              <TableCell
                key={col?.field || index}
                sx={styles.tableHead}
                align={col?.align ? (col.align as TableAlign) : "left"}
              >
                {col.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {isFetchingData ? (
            <TableCell colSpan={columns?.length}>
              <Loader height="150px" />
            </TableCell>
          ) : dataList?.length > 0 ? (
            dataList?.map((row, index: number) => (
              <TableRow key={index}>
                {columns?.map((col, index) => {
                  return (
                    <TableCell
                      key={index}
                      component="td"
                      scope="row"
                      sx={styles.tableCell}
                      align={
                        col?.align
                          ? (col.align as
                              | "left"
                              | "center"
                              | "right"
                              | "justify"
                              | "inherit"
                              | undefined)
                          : "left"
                      }
                    >
                      {col?.render ? (
                        col.render(row) || "-"
                      ) : (
                        <Typography variant="caption">
                          {row[col?.field || 0] || "-"}
                        </Typography>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableCell colSpan={columns?.length} borderBottom={false}>
              <Box sx={styles.noDataContainer}>
                <img src={NoSearchIcon} alt="no data" />
                <Typography sx={styles.noDataMsg}>No records found</Typography>
              </Box>
            </TableCell>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PolicyPage = ({ selectedAccount }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [tabValue, setTabValue] = useState("0");
  const [isAddPolicy, setIsAddPolicy] = useState(false);
  const [isEditPolicy, setIsEditPolicy] = useState(false);
  const [modifyPolicyData, setModifyPolicyData] = useState(null);
  const [isOpenTopic, setIsOpenTopic] = useState(false);
  const [isOpenEntity, setIsOpenEntity] = useState(false);
  const [policyData, setPolicyData] = useState([]);
  const [topicData, setTopicData] = useState([]);
  const [entityData, setEntityData] = useState([]);
  const [isDeletePolicy, setIsDeletePolicy] = useState<{
    id: string;
    policy: string;
  } | null>(null);
  const [isFetchingPolicies, setIsFetchingPolicies] = useState(false);
  const [isFetchingTopics, setIsFetchingTopics] = useState(false);
  const [appList, setAppList] = useState([]);
  const [showDeleteTopic, setShowDeleteTopic] = useState(false);
  const [topicDelete, setTopicDelete] = useState<{
    _id: String;
    topic: string;
  } | null>(null);
  const [showApplyMsg, setShowApplyMsg] = useState("");
  const [openApplyDialog, setOpenApplyDialog] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [policyActionsMenu, setPolicyActionsMenu] =
    useState<null | HTMLElement>(null);
  const isPolicyActionsMenuOpen = Boolean(policyActionsMenu);
  const [selectedRow, setSelectedRow] = useState<string>("");
  const [viewPolicy, setViewPolicy] = useState({});
  const [isViewPolicy, setIsViewPolicy] = useState<boolean>(false);
  const isMenuOpen = Boolean(menuAnchor);

  const getAppsData = async () => {
    const payload = {
      customerId: getCustomerId(),
    };
    const res = await dispatch(fetchFrameworksSlice(payload));
    if (res.payload) {
      setAppList(res.payload.data?.map((app) => app?.name));
    } else {
      setAppList([]);
    }
  };

  const fetchEntities = async () => {
    const payload = {
      customerId: getCustomerId(),
    };
    await dispatch(fetchEntitiesSlice(payload));
  };

  const fetchTopic = async () => {
    const payload = {
      customerId: getCustomerId(),
    };
    await dispatch(fetchDefinedTopicsSlice(payload));
  };

  const closeDeleteTopicDialog = () => {
    setShowDeleteTopic(false);
    setTopicDelete(null);
  };

  useEffect(() => {
    if (policyActionsMenu) {
      setSelectedRow(policyActionsMenu.id);
      return;
    }
    setSelectedRow("");
  }, [policyActionsMenu]);

  useEffect(() => {
    getAppsData();
    fetchEntities();
    fetchTopic();
  }, []);

  const theme = useTheme();
  const styles = {
    main: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
    },
    name: {
      fontSize: "13px",
      lineheight: "24px",
    },
    lastOccur: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },

    tableCellLast: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      gap: theme.spacing(1.5),
    },
    tableCellAction: {
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      color: theme.palette.primaryBlue.main,
      cursor: "pointer",
    },
    tableActionDivider: {
      height: "18px",
      margin: theme.spacing(0.26, 0),
      width: "1px",
      background: theme.palette.surface20.main,
    },
    deleteIcon: {
      color: theme.palette.primaryBlue.main,
      width: "18px",
      cursor: "pointer",
      ":hover": {
        color: theme.palette.secondaryBlue.main,
      },
    },
    mainContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3.5),
    },
    tabPanel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    statusIcon: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
      justifyContent: "left",
    },
    menuItem: {
      padding: theme.spacing(1),
    },
    menuItemText: {
      fontSize: "13px",
    },
    betaTag: {
      backgroundColor: theme.palette.surface20.main,
      paddingX: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      borderRadius: theme.spacing(0.5),
      fontSize: "10px",
      color: theme.palette.surface80.main,
    },
    flex: { display: "flex", alignItems: "center", gap: theme.spacing(1) },
    flexCol: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
  };

  const handleTabChange = (event: Event | SyntheticEvent, newValue: string) =>
    setTabValue(newValue.toString());

  const handleAddDialog = (newValue) => {
    switch (Number(newValue)) {
      case 0:
        setIsAddPolicy(true);
        break;
      case 1:
        setIsOpenTopic(true);
        break;
      case 2:
        setIsOpenEntity(true);
        break;
      default:
        break;
    }
  };

  const onCloseDeleteDialog = () => {
    setIsDeletePolicy(null);
  };

  const deleteTopic = async () => {
    if (topicDelete?._id) {
      const params = {
        customerId: getCustomerId(),
        topicId: topicDelete?._id,
      };
      const response = await dispatch(deleteTopicSlice(params));
      const payload = response?.payload;
      if (payload?.status_code === TOPIC_STATUS_CODES.success) {
        Toaster(TOASTER_TYPES.SUCCESS, payload?.message);
        fetchTopics();
      } else {
        Toaster(TOASTER_TYPES.FAIL, "Unable to delete Topic. Please try again");
      }
      closeDeleteTopicDialog();
    }
  };

  useEffect(() => {
    if (showApplyMsg) {
      setTimeout(() => {
        Toaster(TOASTER_TYPES.SUCCESS, showApplyMsg);
        setShowApplyMsg("");
      }, 1000);
    }
  }, [showApplyMsg]);

  const onDeletePolicy = async () => {
    const payload = {
      customerId: getCustomerId(),
      id: isDeletePolicy?.id,
    };
    const response = await dispatch(deletePolicyAsync(payload));
    if (response?.payload?.message) {
      setIsDeletePolicy(null);
      Toaster(TOASTER_TYPES.SUCCESS, response?.payload?.message);
      fetchPolicies();
    }
  };

  const policyTableCols: Array<TableCol> = [
    {
      title: "Policy Name",
      render: (row: any) => (
        <Typography sx={styles.name}>{row?.name}</Typography>
      ),
    },
    {
      title: "Type",
      field: "type",
      render: (row: any) => (
        <Typography sx={styles.name}>
          {capitalizeFirstLetter(row?.type)}
        </Typography>
      ),
    },
    {
      title: "Configuration",
      field: "config",
      render: (row: any) => {
        return (
          <Box sx={styles.flexCol}>
            <Box sx={styles.flex}>
              <Typography variant="body2" color={theme.palette.surface80.main}>
                Semantic Guardrail
              </Typography>
              <Typography
                variant="caption"
                color={theme.palette.surface50.main}
              >
                | {row?.config?.semanticGuardrail?.entities?.length || 0}{" "}
                Entities, {row?.config?.semanticGuardrail?.topics?.length || 0}{" "}
                Topics
              </Typography>
            </Box>
            <Box sx={styles.flex}>
              <Typography variant="body2" color={theme.palette.surface80.main}>
                Exceptions
              </Typography>
              <Typography
                variant="caption"
                color={theme.palette.surface50.main}
              >
                |{" "}
                {row?.config?.exception?.grantFullAccess
                  ? "Allowed"
                  : "Not Allowed"}{" "}
              </Typography>
            </Box>
            {/*<Box sx={styles.flex}>
              <Typography variant="body2" color={theme.palette.surface80.main}>
                Confidentiality Tags
              </Typography>
              <Typography
                variant="caption"
                color={theme.palette.surface50.main}
              >
                | {row?.config?.ConfidentialityTags || "-"}
              </Typography>
            </Box>*/}
          </Box>
        );
      },
    },
    {
      title: "Owner",
      field: "owner",
      render: (row: any) => (
        <Typography sx={styles.name}>{row?.userName}</Typography>
      ),
    },
    {
      title: "Added On",
      field: "addedOn",
      render: (row) => {
        const dateString = moment(row?.addedOn).format("yyyy-MM-DD");
        const hoursAndMinutes = moment(row?.addedOn).format("HH:mm:ss");

        return (
          <Typography sx={styles.name}>
            {`${dateString} | ${hoursAndMinutes}`}
          </Typography>
        );
      },
    },
    {
      title: "",
      field: "actions",
      align: "right",
      render: (row) => {
        return (
          <div>
            <TextButton
              onClick={() => {
                console.log("hey");
                setViewPolicy(row);
                setIsViewPolicy(true);
              }}
            >
              View Details
            </TextButton>
            <IconButton
              id={row.id}
              onClick={(e) => setPolicyActionsMenu(e.currentTarget)}
            >
              <MoreVert htmlColor={theme.palette.primaryBlue.main} />
            </IconButton>
            <Menu
              open={!!policyActionsMenu && selectedRow === row.id}
              id={row.id}
              anchorEl={policyActionsMenu}
              onClose={() => setPolicyActionsMenu(null)}
            >
              <MenuItem sx={styles.menuItem}>
                <Typography
                  sx={styles.menuItemText}
                  onClick={() => {
                    setIsEditPolicy(true);
                    setPolicyActionsMenu(null);
                  }}
                >
                  Edit Policy
                </Typography>
              </MenuItem>
              <MenuItem sx={styles.menuItem}>
                <Typography
                  sx={styles.menuItemText}
                  onClick={() => {
                    console.log({ row });
                    setIsDeletePolicy({
                      id: row?.id,
                      policy: row?.name,
                    });
                    setPolicyActionsMenu(null);
                  }}
                >
                  Delete Policy
                </Typography>
              </MenuItem>
            </Menu>
          </div>
        );
      },
    },
  ];

  const getStatusIcon = (status) => {
    switch (status) {
      case TOPIC_STATUS.SUCCESS:
        return (
          <CheckCircleOutlineIcon
            sx={{ color: theme.palette.success.main, fontSize: "20px" }}
          />
        );
      case TOPIC_STATUS.EVALUATING:
      case TOPIC_STATUS.TRAINING:
      case TOPIC_STATUS.PENDING:
        return (
          <AccessTimeIcon
            sx={{ color: theme.palette.warning.main, fontSize: "20px" }}
          />
        );
      case TOPIC_STATUS.FAIL:
        return (
          <ErrorOutlineIcon
            sx={{
              color: theme.palette.critical.main,
              fontSize: "20px",
            }}
          />
        );
      case TOPIC_STATUS.ABORTED:
      case TOPIC_STATUS.CANCELLED:
      case TOPIC_STATUS.MIN_SAMPLE_CHECK:
      case TOPIC_STATUS.LOAD_DATA:
      case TOPIC_STATUS.QUEUED:
      case TOPIC_STATUS.EMBEDDING_GENERATION:
      case TOPIC_STATUS.CENTROID_CHECK:
      case TOPIC_STATUS.VARIANCE_CHECK:
        return (
          <AccessTimeIcon
            sx={{ color: theme.palette.warning.main, fontSize: "20px" }}
          />
        );
      default:
        return "-";
    }
  };

  const topicsCols: Array<TableCol> = [
    {
      title: "Topic",
      render: (row) => (
        <Typography sx={styles.name}>{row?.topic_name || "-"}</Typography>
      ),
    },
    {
      title: "Description",
      field: "topic_description",
      render: (row) => (
        <Typography sx={styles.name}>
          {row?.topic_description || "-"}
        </Typography>
      ),
    },
    {
      title: "Added On",
      field: "createdOn",
      render: (row) => {
        const dateString = moment(row?.metadata?.createdAt).format(
          "yyyy-MM-DD"
        );
        const hoursAndMinutes = moment(row?.metadata?.createdAt).format(
          "HH:mm:ss"
        );
        return (
          <Typography sx={styles.name}>
            {`${dateString || "-"} | ${hoursAndMinutes || "-"}`}
          </Typography>
        );
      },
    },
    {
      title: "Status",
      field: "status",
      align: "left",
      render: (row) => {
        const status = row?.model_info?.status;
        const errorMessage = row?.model_info?.errorMessage;

        if (status) {
          const icon = getStatusIcon(status);
          const displayStatus = capitalizeFirstLetter(
            TOPIC_STATUS_VALUES[status]
          );
          return (
            <Box sx={styles.statusIcon}>
              {icon}
              <Tooltip title={errorMessage || displayStatus}>
                <div>
                  <Typography variant="caption">{displayStatus}</Typography>
                </div>
              </Tooltip>
            </Box>
          );
        }
        return <>-</>;
      },
    },
    {
      title: "",
      field: "actions",
      align: "center",
      render: (row) => {
        return (
          <Box sx={styles.tableCellLast}>
            <Box>
              <TextButton
                sx={styles.tableCellAction}
                onClick={(e) => setMenuAnchor(e.currentTarget)}
              >
                Apply
              </TextButton>
              <Menu
                open={isMenuOpen}
                anchorEl={menuAnchor}
                onClose={() => setMenuAnchor(null)}
              >
                <MenuItem sx={styles.menuItem}>
                  <Typography
                    sx={styles.menuItemText}
                    onClick={() => {
                      setShowApplyMsg(
                        "Topic successfully applied to all Applications"
                      );
                      setMenuAnchor(null);
                    }}
                  >
                    Apply to all applications
                  </Typography>
                </MenuItem>
                <MenuItem sx={styles.menuItem}>
                  <Typography
                    sx={styles.menuItemText}
                    onClick={() => {
                      setOpenApplyDialog(true);
                      setMenuAnchor(null);
                    }}
                  >
                    Select Application
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              sx={styles.tableActionDivider}
            />
            <Box>
              <TextButton sx={styles.tableCellAction}>Modify</TextButton>
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              sx={styles.tableActionDivider}
            />
            <TextButton
              onClick={() => {
                setTopicDelete({ _id: row?._id, topic: row?.topic_name });
                setShowDeleteTopic(true);
              }}
            >
              <DeleteIcon sx={styles.deleteIcon} />
            </TextButton>
          </Box>
        );
      },
    },
  ];

  const entityCols: Array<{
    title: string;
    render: (any) => JSX.Element;
    align?: TableAlign;
    field?: string;
  }> = [
    {
      title: "Entities",
      render: (row) => (
        <Typography sx={styles.name}>
          {row?.entity || row?.customEntity[0] || "-"}
        </Typography>
      ),
    },
    {
      title: "Added On",
      field: "createdOn",
      render: (row) => {
        const dateString = moment(row?.metadata?.createdAt).format(
          "yyyy-MM-DD"
        );
        const hoursAndMinutes = moment(row?.metadata?.createdAt).format(
          "HH:mm:ss"
        );
        return (
          <Typography sx={styles.name}>
            {`${dateString || "-"} | ${hoursAndMinutes || "-"}`}
          </Typography>
        );
      },
    },
    {
      title: "",
      field: "actions",
      align: "center",
      render: (row) => {
        return (
          <Box sx={styles.tableCellLast}>
            <Box>
              <TextButton
                startIcon={<ConstructionIcon />}
                sx={styles.tableCellAction}
              >
                Modify Entity
              </TextButton>
            </Box>

            <Divider
              orientation="vertical"
              flexItem
              sx={styles.tableActionDivider}
            />
            <DeleteIcon sx={styles.deleteIcon} />
          </Box>
        );
      },
    },
  ];

  const tabData = [
    {
      id: "0",
      value: "policy",
      label: "Policy",
      tableCol: policyTableCols,
      disableAdd: false,
    },
  ];

  const getButtonText = () => {
    switch (Number(tabValue)) {
      case 0:
        return "Policy";
      case 1:
        return "Topic";
      case 2:
        return "Entity";
      default:
        return "Policy";
    }
  };

  const fetchPolicies = async () => {
    setIsFetchingPolicies(true);
    const payload = {
      customerId: getCustomerId(),
    };
    const res = await dispatch(listPoliciesAsync(payload));
    if (res.payload) {
      setPolicyData(res.payload);
    }
    setIsFetchingPolicies(false);
  };

  const fetchTopics = async () => {
    setIsFetchingTopics(true);
    const payload = {
      customerId: getCustomerId(),
    };
    const res = await dispatch(fetchTopicSlice(payload));
    if (res.payload) {
      setTopicData(res.payload);
    }
    setIsFetchingTopics(false);
  };

  useEffect(() => {
    fetchPolicies();
    fetchTopics();
  }, []);

  return (
    <>
      <Box sx={styles.main}>
        <Typography color={theme.palette.surface100.main} variant="body2">
          Policy Settings
        </Typography>
        <Box sx={styles.mainContent}>
          <Box sx={styles.tabPanel}>
            <Tabs value={Number(tabValue)} onChange={handleTabChange}>
              {tabData?.map((item) => (
                <Tab
                  label={item?.label}
                  icon={
                    <>
                      {item?.label === "Topic" && (
                        <Box sx={styles.betaTag}>BETA</Box>
                      )}
                    </>
                  }
                  iconPosition="end"
                />
              ))}
            </Tabs>
            <Button
              variant="contained"
              disabled={tabData.find((tab) => tab.id === tabValue)?.disableAdd}
              onClick={() => handleAddDialog(tabValue)}
            >
              Add {getButtonText()}
            </Button>
          </Box>

          <TabPanel value={tabValue} index="0">
            <DataTable
              columns={policyTableCols}
              dataList={policyData}
              isFetchingData={isFetchingPolicies}
            />
          </TabPanel>
          <TabPanel value={tabValue} index="1">
            <DataTable
              columns={topicsCols}
              dataList={topicData}
              isFetchingData={isFetchingTopics}
            />
          </TabPanel>
          <TabPanel value={tabValue} index="2">
            <DataTable columns={entityCols} dataList={[]} />
          </TabPanel>
        </Box>
      </Box>
      <AddPolicy
        isEdit={isEditPolicy}
        isOpen={isAddPolicy || isEditPolicy}
        selectedAccount={selectedAccount}
        handleClose={() => {
          setIsAddPolicy(false);
          setIsEditPolicy(false);
        }}
        fetchPolicies={fetchPolicies}
        buttonText={isEditPolicy ? "Update" : "Add"}
        dialogHeader={isEditPolicy ? "Modify Policy" : "Add Policy"}
        policyDetails={modifyPolicyData}
      />
      <AddTopic
        isOpen={isOpenTopic}
        handleClose={() => setIsOpenTopic(false)}
        fetchTopics={fetchTopics}
      />
      <AddEntity
        isOpen={isOpenEntity}
        handleClose={() => setIsOpenEntity(false)}
      />
      <DeletePolicy
        policyName={isDeletePolicy?.policy}
        open={isDeletePolicy}
        onDelete={onDeletePolicy}
        onClose={onCloseDeleteDialog}
      />
      <ApplyTopicToAppsDialog
        open={openApplyDialog}
        onClose={() => setOpenApplyDialog(false)}
        apps={appList}
        onSubmit={(appName) => {
          setShowApplyMsg(`Topic successfully applied to ${appName}`);
          setOpenApplyDialog(false);
        }}
      />
      <DeleteTopic
        open={showDeleteTopic}
        onClose={closeDeleteTopicDialog}
        onDelete={deleteTopic}
        topicName={topicDelete?.topic}
      />
      {isViewPolicy && (
        <ViewPolicy
          open={isViewPolicy}
          policyData={viewPolicy}
          onClose={() => {
            setIsViewPolicy(false);
            setViewPolicy({});
          }}
          onEdit={(policyData) => {
            setIsEditPolicy(true);
            setIsViewPolicy(false);

            setModifyPolicyData(policyData);
          }}
        />
      )}
    </>
  );
};

export default PolicyPage;
