import { IconButton, useTheme } from "@mui/material";
import Box from "../common/Box";
import { Dialog, DialogActions, DialogTitle } from "../common/Dialog";
import Divider from "../common/Divider";
import Typography from "../common/Typography";
import { useSelector } from "react-redux";
import Button from "../common/Button";
import CloseIcon from "@mui/icons-material/Close";
import { convertUTCToLocal } from "../../utils/commonUtils";
import moment from "moment";
import NoData from "../common/NoData";

const ViewPolicy = ({ policyData, open, onClose, onEdit }) => {
  const theme = useTheme();
  const { entities, topics } = useSelector((state: any) => state.policy);

  console.log({ entities, topics });
  const styles = {
    manrope: {
      fontFamily: "Manrope",
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      padding: theme.spacing(3),
    },
    policyDetails: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    policyCreate: {
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "center",
    },
    violationBox: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "baseline",
    },
    violationCount: {
      fontSize: "32px",
    },
    violationText: {
      fontSize: theme.spacing(2),
    },
    dialogTitle: {
      color: theme.palette.surface80.main,
      fontSize: theme.spacing(2.25),
      padding: 0,
      textTransform: "none",
    },
    closeIcon: {
      fontSize: theme.spacing(2),
      width: theme.spacing(2),
    },
    dialogHeader: {
      display: "flex",
      justifyContent: "space-between",
      paddingX: theme.spacing(3),
      paddingTop: theme.spacing(3),
      alignItems: "center",
    },
    groupRows: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    groupListing: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    entityBlock: {
      backgroundColor: theme.palette.surface0.main,
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
      borderRadius: "8px",
      width: "49%",
      height: "250px",
      overflowY: "auto",
    },
  };

  const groups = policyData?.config?.userAndGroups?.identities || [];
  const topicsList =
    topics?.filter((topic) =>
      policyData?.config?.semanticGuardrail?.topics?.includes(topic?.id)
    ) || [];
  const entitiesList =
    entities?.filter((entity) =>
      policyData?.config?.semanticGuardrail?.entities?.includes(entity?.id)
    ) || [];

  const exceptionConfig =
    policyData?.config?.exception?.grantFullAccess || false;

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <Box sx={styles.dialogHeader}>
        <Typography>{policyData?.name}</Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon sx={styles.closeIcon} />
        </IconButton>
      </Box>
      <Box sx={styles.dialogContent}>
        <Box sx={styles.policyDetails}>
          <Box sx={styles.policyCreate}>
            <Box>
              <Typography
                color={theme.palette.surface40.main}
                variant="caption"
              >
                Added On
              </Typography>
              <Typography variant="body2" color={theme.palette.surface80.main}>
                {moment(convertUTCToLocal(policyData?.metadata?.createdAt))
                  .format("MM/DD/YYYY")
                  .toString()}
              </Typography>
            </Box>

            <Box>
              <Typography
                color={theme.palette.surface40.main}
                variant="caption"
              >
                Owner
              </Typography>
              <Typography variant="body2" color={theme.palette.surface80.main}>
                {policyData?.userName}
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.violationBox}>
            <Box>
              <Typography
                color={theme.palette.surface40.main}
                variant="caption"
              >
                Exceptions
              </Typography>
              <Typography variant="body2" color={theme.palette.surface80.main}>
                {exceptionConfig ? "Allowed" : "Not Allowed"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={styles.groupRows}>
          <Typography color={theme.palette.surface80.main}>
            {groups?.length} User Groups
          </Typography>
          <Box sx={styles.groupListing}>
            {groups?.map((group) => (
              <Typography
                key={group}
                variant="body2"
                color={theme.palette.surface80.main}
              >
                {group}
              </Typography>
            ))}
          </Box>
        </Box>
        <Divider />
        <Box sx={styles.groupRows}>
          <Typography>Semantic Guardrail Configuration</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={styles.entityBlock}>
              <Typography variant="body2" color={theme.palette.surface80.main}>
                {entitiesList?.length || 0} Entity Groups Restricted
              </Typography>
              <Box sx={styles.groupRows}>
                {entitiesList?.length > 0 ? (
                  entitiesList?.map((entity) => {
                    const subGroups = entity?.entities || [];
                    return (
                      <Box sx={{ ...styles.groupRows, gap: theme.spacing(1) }}>
                        <Typography
                          variant="body2"
                          color={theme.palette.surface80.main}
                        >
                          {entity?.name}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: theme.spacing(0.5),
                          }}
                        >
                          {subGroups.map((subGroup) => (
                            <Typography
                              color={theme.palette.surface50.main}
                              variant="caption"
                            >
                              {subGroup}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <NoData customText="No Entities Added" />
                )}
              </Box>
            </Box>
            <Box sx={styles.entityBlock}>
              <Typography variant="body2" color={theme.palette.surface80.main}>
                {topicsList?.length || 0} Topic Groups Restricted
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: theme.spacing(2),
                }}
              >
                {topicsList?.length > 0 ? (
                  topicsList?.map((topic) => (
                    <Typography
                      variant="body2"
                      color={theme.palette.surface80.main}
                    >
                      {topic?.name}
                    </Typography>
                  ))
                ) : (
                  <NoData customText="No Topics Added" />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/*<DialogActions>
        <Button variant="contained">Edit</Button>
      </DialogActions>*/}
    </Dialog>
  );
};

export default ViewPolicy;
