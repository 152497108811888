import { useTheme } from "@mui/material";
import Box from "../common/Box";
import Typography from "../common/Typography";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchLoaderListSlice } from "../../redux/slices/dataLoaderSlice";
import { getCustomerId } from "../../utils/SessionHelper";
import {
  convertUTCToLocal,
  getDifferenceInDays,
  getFirstNValue,
  getIconForKey,
  getJoinedString,
} from "../../utils/commonUtils";
import { capitalizeFirstLetter } from "../../utils/utils.js";
import { ListLoaderAPI } from "../dashboard-widgets/APIResponseType";
import { DATA_LOADER_DETAILS } from "../../routes/constants";
import { useHistory } from "react-router-dom";
import Tooltip from "../common/Tooltip";
import NoData from "../common/NoData";
import Loader from "../common/Loader";
import { Breadcrumbs, Link } from "../common/Breadcrumbs";
import { AppDispatch } from "../../redux/store";

const DataLoaderListingPage = () => {
  const [loaders, setLoaders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const theme = useTheme();
  const styles = {
    page: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    pageHeader: {
      display: "flex",
      alignItems: "center",
    },
    searchOptions: {
      marginLeft: "auto",
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "center",
    },
    divider: {
      height: "13px",
    },
    tableContainer: {
      backgroundColor: theme.palette.surface10.main,
      borderRadius: "4px",
    },
    loaderType: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    noDataCell: {
      "&.MuiTableCell-root": {
        borderBottom: "none",
      },
    },
    breadCrumbLink: {
      fontSize: theme.typography.caption,
    },
    activeLink: { color: theme.palette.surface80.main },
    blueLink: {
      color: theme.palette.primaryBlue.main,
    },
  };

  const fetchAllDataLoaders = async () => {
    setIsLoading(true);
    const payload = {
      customerId: getCustomerId(),
    };
    const response = await dispatch(fetchLoaderListSlice(payload));
    if (response?.payload) {
      setLoaders(response.payload);
    }
    setIsLoading(false);
  };

  const redirectToDataLoaderPage = (loaderID: string) => {
    history.push(DATA_LOADER_DETAILS + loaderID);
  };

  useEffect(() => {
    fetchAllDataLoaders();
  }, []);

  return (
    <Box sx={styles.page}>
      <Breadcrumbs sx={styles.breadCrumbLink}>
        <Link underline="hover" color="inherit" href="/" sx={styles.blueLink}>
          Home
        </Link>
        <Typography variant="caption" sx={styles.activeLink}>
          All Data Loaders
        </Typography>
      </Breadcrumbs>
      <Box sx={styles.pageHeader}>
        <Typography>
          List of Data Loaders ({loaders ? loaders?.length : 0})
        </Typography>
      </Box>
      <Box sx={styles.tableContainer}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Labels</TableCell>
                <TableCell>Last Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableCell colSpan={4} sx={styles.noDataCell}>
                  <Loader height="150px" />
                </TableCell>
              ) : loaders?.length > 0 ? (
                loaders.map((loader: ListLoaderAPI) => {
                  const updatedAtDate = loader.last_updated
                    ? convertUTCToLocal(loader.last_updated).toString()
                    : null;
                  return (
                    <TableRow
                      onClick={() => redirectToDataLoaderPage(loader.id)}
                    >
                      <TableCell>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface80.main}
                        >
                          {loader.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Box sx={styles.loaderType}>
                          <img
                            src={getIconForKey("data_loader", loader.type)}
                            alt={loader.type}
                            width="20px"
                          />
                          <Typography
                            variant="caption"
                            color={theme.palette.surface80.main}
                          >
                            {capitalizeFirstLetter(loader.type)}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Tooltip title={getJoinedString(loader?.labels, ", ")}>
                          <div>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface80.main}
                            >
                              {getFirstNValue(loader.labels, 2)}
                            </Typography>
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface80.main}
                        >
                          {updatedAtDate
                            ? getDifferenceInDays(
                                new Date(),
                                new Date(updatedAtDate)
                              )
                            : "-"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableCell colSpan={4} sx={styles.noDataCell}>
                  <NoData customText="No data found" />
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default DataLoaderListingPage;
